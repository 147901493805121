import React from 'react';
import '../Realty.scss';

class FAQSection extends React.Component {
  state = {
    questions: [],
  };

  componentDidMount() {
    this.setState({ questions: this.props.questions });
  }

  render() {
    return (
      <section className="section-faq">
        <h2>
          <i id="faqheader" className="fas fa-question-circle"></i> Frequently asked questions
        </h2>
        <div className="rtb-flex">
          <div>
            {this.state.questions.map((question, index) => {
              return (
                <article
                  key={`faq${index}`}
                  data-aos-anchor="#faqheader"
                  data-aos-anchor-placement="bottom-center"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay={300 * index}
                  data-aos-duration="1000"
                >
                  <button
                    onClick={() => {
                      this.setState({ [`showAnswer${index}`]: !this.state[`showAnswer${index}`] });
                    }}
                  >
                    <h3>
                      <span className="faq-number">{index + 1}.</span>
                      {question.question}
                      <i className="far fa-chevron-down"></i>
                    </h3>
                  </button>
                  <p className={this.state[`showAnswer${index}`] ? 'faq-answer' : 'd-none'} data-id="faq-1-answer">
                    {question.answer}
                  </p>
                </article>
              );
            })}
            <div className="show-more">
              <a href="https://blog.capcenter.com/faq" className="show-more-link" target="_blank" rel="noopener noreferrer">
                See more questions
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FAQSection;
